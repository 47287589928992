import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  makeStyles,
  createStyles,
  Checkbox,
  TextField,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { SelectOption } from './FilterControls';

interface AutocompleteMultiProps {
  id: string;
  options: SelectOption[];
  value: SelectOption[];
  onChange: (_, value: SelectOption[]) => void;
  label: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    checkboxIcon: {
      margin: 0,
    },
    selectOption: {
      fontSize: 'small',
    },
  })
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const trimIfLongerThan = (value: string, length: number) => {
  if (value.length > length) {
    return `${value.substr(0, length)}...`;
  } else {
    return value;
  }
};

const AutocompleteMulti: React.FC<AutocompleteMultiProps> = ({
  id,
  options,
  value,
  onChange,
  label,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      id={id}
      options={options}
      getOptionLabel={option => `${trimIfLongerThan(option.name, 21)}`}
      getOptionSelected={(option, value) => {
        return value.id === option.id;
      }}
      value={value}
      renderOption={(option, { selected }) => (
        <div className={classes.selectOption}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            className={classes.checkboxIcon}
            checked={selected}
          />
          {option.name}
        </div>
      )}
      onChange={onChange}
      renderInput={params => <TextField {...params} label={label} />}
    />
  );
};

export default AutocompleteMulti;
