import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Paper,
  Popover,
  Link,
} from '@material-ui/core';
import { BookingRange, isBooking } from '../../services/models';

const PURPLE_UPDATES_PROJECT_URL =
  'https://updates.purple.tech/ManageProjects/RenderProjectDashboard/OpenAir/';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      backgroundColor: '#BFBFBF',
      color: theme.palette.getContrastText('#BFBFBF'),
      minWidth: theme.spacing(24),
      padding: theme.spacing(1),
    },
    flexContainer: {
      display: 'flex',
    },
    hoursLeft: {
      marginLeft: 'auto',
      fontStyle: 'italic',
    },
    links: {
      marginTop: theme.spacing(1),
    },
  })
);

interface BookingPopoverProps {
  bookingRange: BookingRange;
  anchorEl: any;
  handleClose: () => void;
}

export const BookingPopover: React.FC<BookingPopoverProps> = ({
  bookingRange,
  anchorEl,
  handleClose,
}) => {
  const classes = useStyles();
  const popupOpen = Boolean(anchorEl);

  // If the Power BI subreport that users typically drill down to
  // can be improved so the Project/ID isn't a locked field, we can
  // save the user from having to drill down into that valuable report
  const powerBiBase = process.env.REACT_APP_POWER_BI_REPORT_BASE;
  if (!powerBiBase) {
    console.error(
      'Application not configured. Please provide Power BI base report url.'
    );
  }

  return (
    <Popover
      open={popupOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Paper className={classes.popover}>
        <Typography variant="subtitle2" component="div">
          {bookingRange.client}
        </Typography>
        <Typography variant="caption" component="div">
          {bookingRange.project}
        </Typography>
        <Typography variant="caption" component="div">
          {bookingRange.salesRepresentative
            ? `AM: ${bookingRange.salesRepresentative}`
            : ''}
        </Typography>
        <Typography variant="caption" component="div">
          {bookingRange.source ? `Source: ${bookingRange.source}` : ''}
        </Typography>

        {isBooking(bookingRange.bookingType) && (
          <div className={classes.links}>
            <Typography variant="caption">
              <Link
                target="_blank"
                rel="noopener"
                href={`${powerBiBase}${bookingRange.projectId}`}
              >
                Budget Report
              </Link>
            </Typography>
            <div className={classes.flexContainer}>
              <Typography variant="caption">
                <Link
                  target="_blank"
                  rel="noopener"
                  href={`${PURPLE_UPDATES_PROJECT_URL}${bookingRange.projectId}`}
                >
                  PurpleUpdates
                </Link>
              </Typography>
              <Typography variant="caption" className={classes.hoursLeft}>
                {bookingRange.getHoursRemaining == null
                  ? 'Unknown'
                  : bookingRange.getHoursRemaining!()}{' '}
                hours left
              </Typography>
            </div>
          </div>
        )}
      </Paper>
    </Popover>
  );
};
