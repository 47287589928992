import { useReducer, Dispatch } from 'react';
import { saveFilterState } from '../services/filterStateService';

export enum ViewMode {
  Booking = 'Booking',
  Project = 'Project',
}

export enum BookingType {
  Soft = 'Soft',
  Unassigned = 'Unassigned',
}

export class FilterState {
  constructor(
    public viewMode: ViewMode = ViewMode.Project,
    public squadId: number = 9,
    public searchByRole: string[] = [],
    public bookingTypes: BookingType[] = [],
    public searchByProject: string[] = [],
    public searchByClient: string[] = [],
    public searchByConsultant: string[] = [],
    public searchByCapability: string[] = [],
    public freeTextSearch: string = ''
  ) {}
}

export enum FilterDispatchType {
  SetViewMode,
  SetSquad,
  SetRoleFilter,
  SetBookingType,
  SetSearchByProject,
  SetSearchByConsultant,
  SetSearchByClient,
  SetSearchByCapability,
  Reset,
  SetFreeTextSearch,
}

type FilterActionObject<
  T extends FilterDispatchType,
  V extends keyof FilterState
> = { type: T } & Pick<FilterState, V>;

type FilterActionReseObject<T extends FilterDispatchType> = { type: T };

export type FilterAction =
  | FilterActionReseObject<FilterDispatchType.Reset>
  | FilterActionObject<FilterDispatchType.SetSearchByProject, 'searchByProject'>
  | FilterActionObject<FilterDispatchType.SetSearchByConsultant, 'searchByConsultant'>
  | FilterActionObject<FilterDispatchType.SetViewMode, 'viewMode'>
  | FilterActionObject<FilterDispatchType.SetSquad, 'squadId'>
  | FilterActionObject<FilterDispatchType.SetRoleFilter, 'searchByRole'>
  | FilterActionObject<FilterDispatchType.SetSearchByClient, 'searchByClient'>
  | FilterActionObject<FilterDispatchType.SetSearchByCapability, 'searchByCapability'>
  | FilterActionObject<FilterDispatchType.SetBookingType, 'bookingTypes'>
  | FilterActionObject<FilterDispatchType.SetFreeTextSearch, 'freeTextSearch'>;

const filterReducer = (
  currentState: FilterState,
  action: FilterAction
): FilterState => {
  const newState = { ...currentState };
  switch (action.type) {
    case FilterDispatchType.SetViewMode:
      newState.viewMode = action.viewMode;
      break;
    case FilterDispatchType.SetSquad:
      newState.searchByRole = [];
      newState.searchByClient = [];
      newState.searchByProject = [];
      newState.searchByConsultant = [];
      newState.bookingTypes = [];
      newState.squadId = action.squadId;
      break;
    case FilterDispatchType.SetRoleFilter:
      newState.searchByRole = action.searchByRole;
      break;
    case FilterDispatchType.SetBookingType:
      newState.bookingTypes = action.bookingTypes;
      break;
    case FilterDispatchType.SetSearchByProject:
      newState.searchByProject = action.searchByProject;
      break;
    case FilterDispatchType.SetSearchByConsultant:
      newState.searchByConsultant = action.searchByConsultant;
      break;
    case FilterDispatchType.SetSearchByClient:
      newState.searchByClient = action.searchByClient;
      break;
    case FilterDispatchType.SetSearchByCapability:
      newState.searchByCapability = action.searchByCapability;
      break;
    case FilterDispatchType.SetFreeTextSearch:
      newState.freeTextSearch = action.freeTextSearch;
      break;
    case FilterDispatchType.Reset:
      newState.searchByRole = [];
      newState.searchByClient = [];
      newState.searchByProject = [];
      newState.searchByConsultant = [];
      newState.searchByCapability = [];
      newState.bookingTypes = [];
      newState.freeTextSearch = '';
      break;
  }

  saveFilterState(newState);
  return newState;
};

export type FilterDispatch = Dispatch<FilterAction>;

const useFilterReducer = (
  initialState: FilterState
): [FilterState, FilterDispatch] => useReducer(filterReducer, initialState);

export default useFilterReducer;
