import { FilterState } from '../hooks/useFilterReducer';

const PEDRO_FILTER_STATE_KEY = 'PedroFilterState';

export function saveFilterState(state: FilterState) {
  const stateToSave: Partial<FilterState> = { ...state };
  delete stateToSave.searchByProject;

  localStorage.setItem(PEDRO_FILTER_STATE_KEY, JSON.stringify(stateToSave));

  if (window.history.pushState) {
    const queryString = Object.keys(state)
      .map(key => `${key}=${state[key]}`)
      .join('&');

    const loc = window.location;
    const newurl = `${loc.protocol}//${loc.host}${loc.pathname}?${queryString}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

export const fetchParam = <T>(
  index: string,
  params: URLSearchParams,
  local: Partial<FilterState>
) => {
  let savedValue = params.get(index) || local[index];
  try {
    if (index === 'searchByConsultant' && typeof savedValue === 'string') {
      savedValue = savedValue.split(',');
    }
    return savedValue as T;
  } catch (e) {
    return undefined;
  }
};

export function loadFilterState() {
  const params = new URLSearchParams(window.location.search);

  let localStorageFilterState = {} as Partial<FilterState>;
  try {
    const localStorageFilterString =
      localStorage.getItem(PEDRO_FILTER_STATE_KEY) ?? '{}';

    localStorageFilterState = JSON.parse(localStorageFilterString);
  } catch {
    localStorage.removeItem(PEDRO_FILTER_STATE_KEY);
  }

  const defaultFilters = new FilterState();
  const keys = Object.keys(defaultFilters);

  const savedState = keys.reduce((previous, key) => {
    const value = fetchParam(key, params, localStorageFilterState);
    if (value && value !== 'null') {
      previous[key] = value;
    }
    return previous;
  }, {});

  return { ...defaultFilters, ...savedState };
}
