import { Dispatch } from 'react';
import { filterAndMapPeeps, PeepRow, transformBookingsToPeeps } from './mapper';
import { Peep, PeepProject, Squad } from './models';

export enum ViewMode {
  Booking = 'Booking',
  Project = 'Project',
}

export enum BookingType {
  Soft = 'Soft',
  Unassigned = 'Unassigned',
}

export class AppState {
  constructor(
    public viewMode: ViewMode = ViewMode.Project,
    public squadId: number = 9,
    public searchByRole: string[] = [],
    public bookingTypes: BookingType[] = [],
    public searchByProject: string[] = [],
    public searchByClient: string[] = [],
    public searchByConsultant: string[] = [],
    public searchByCapability: string[] = [],
    public freeTextSearch: string = '',
    public squads: Squad[] = [],
    public unfilteredPeeps: Peep[] = [],
    public lastUpdated: number | undefined = undefined,
    public hasErrors: boolean = false,
    public isLoadingData: boolean = true,
    public projectsAndPeeps: (PeepProject | PeepRow)[] | undefined = undefined, // Used by Project View
    public filteredPeepRows: PeepRow[] | undefined = undefined // Used by Booking View
  ) {}
}

export enum DispatchType {
  SetViewMode,
  SetSquad,
  SetRoleFilter,
  SetBookingType,
  SetSearchByProject,
  SetSearchByConsultant,
  SetSearchByClient,
  SetSearchByCapability,
  ResetFilter,
  SetFreeTextSearch,
  SetSquadResponse,
  SetBookingsResponse,
  SetIsLoading,
  SetHasErrors,
}

type ActionObject<T extends DispatchType, V extends keyof AppState> = {
  type: T;
} & Pick<AppState, V>;

type FilterResetActionObject<T extends DispatchType> = { type: T };

export type AppAction =
  | FilterResetActionObject<DispatchType.ResetFilter>
  | ActionObject<DispatchType.SetSearchByProject, 'searchByProject'>
  | ActionObject<DispatchType.SetSearchByConsultant, 'searchByConsultant'>
  | ActionObject<DispatchType.SetViewMode, 'viewMode'>
  | ActionObject<DispatchType.SetSquad, 'squadId'>
  | ActionObject<DispatchType.SetRoleFilter, 'searchByRole'>
  | ActionObject<DispatchType.SetSearchByClient, 'searchByClient'>
  | ActionObject<DispatchType.SetSearchByCapability, 'searchByCapability'>
  | ActionObject<DispatchType.SetBookingType, 'bookingTypes'>
  | ActionObject<DispatchType.SetFreeTextSearch, 'freeTextSearch'>
  | ActionObject<DispatchType.SetSquadResponse, 'squads'>
  | ActionObject<DispatchType.SetBookingsResponse, 'unfilteredPeeps'>
  | ActionObject<DispatchType.SetIsLoading, 'isLoadingData'>
  | ActionObject<DispatchType.SetHasErrors, 'hasErrors'>;

export type AppStateDispatch = Dispatch<AppAction>;

export const appReducer = (
  currentState: AppState,
  action: AppAction
): AppState => {
  const newState = { ...currentState };

  switch (action.type) {
    case DispatchType.SetViewMode:
      newState.viewMode = action.viewMode;
      break;
    case DispatchType.SetSquad:
      newState.searchByRole = [];
      newState.searchByClient = [];
      newState.searchByProject = [];
      newState.searchByConsultant = [];
      newState.bookingTypes = [];
      newState.squadId = action.squadId;
      /* TODO when apollo cache is returning results we're winding up with the wrong kind of filter options */
      break;
    case DispatchType.SetRoleFilter:
      newState.searchByRole = action.searchByRole;
      const roleFilteredPeeps = filterAndMapPeeps(
        newState.unfilteredPeeps,
        newState
      );
      newState.projectsAndPeeps = roleFilteredPeeps.projectsAndPeeps;
      newState.filteredPeepRows = roleFilteredPeeps.filteredPeepRows;
      break;
    case DispatchType.SetBookingType:
      newState.bookingTypes = action.bookingTypes;
      const bookingTypeFilteredPeeps = filterAndMapPeeps(
        newState.unfilteredPeeps,
        newState
      );
      newState.projectsAndPeeps = bookingTypeFilteredPeeps.projectsAndPeeps;
      newState.filteredPeepRows = bookingTypeFilteredPeeps.filteredPeepRows;
      break;
    case DispatchType.SetSearchByProject:
      newState.searchByProject = action.searchByProject;
      const projectFilteredPeeps = filterAndMapPeeps(
        newState.unfilteredPeeps,
        newState
      );
      newState.projectsAndPeeps = projectFilteredPeeps.projectsAndPeeps;
      newState.filteredPeepRows = projectFilteredPeeps.filteredPeepRows;
      break;
    case DispatchType.SetSearchByConsultant:
      newState.searchByConsultant = action.searchByConsultant;
      const consultantFilteredPeeps = filterAndMapPeeps(
        newState.unfilteredPeeps,
        newState
      );
      newState.projectsAndPeeps = consultantFilteredPeeps.projectsAndPeeps;
      newState.filteredPeepRows = consultantFilteredPeeps.filteredPeepRows;
      break;
    case DispatchType.SetSearchByClient:
      newState.searchByClient = action.searchByClient;
      const clientFilteredPeeps = filterAndMapPeeps(
        newState.unfilteredPeeps,
        newState
      );
      newState.projectsAndPeeps = clientFilteredPeeps.projectsAndPeeps;
      newState.filteredPeepRows = clientFilteredPeeps.filteredPeepRows;
      break;
    case DispatchType.SetSearchByCapability:
      newState.searchByCapability = action.searchByCapability;
      const capabilityFilteredPeeps = filterAndMapPeeps(
        newState.unfilteredPeeps,
        newState
      );
      newState.projectsAndPeeps = capabilityFilteredPeeps.projectsAndPeeps;
      newState.filteredPeepRows = capabilityFilteredPeeps.filteredPeepRows;
      break;
    case DispatchType.SetFreeTextSearch:
      newState.freeTextSearch = action.freeTextSearch;
      break;
    case DispatchType.ResetFilter:
      newState.searchByRole = [];
      newState.searchByClient = [];
      newState.searchByProject = [];
      newState.searchByConsultant = [];
      newState.searchByCapability = [];
      newState.bookingTypes = [];
      newState.freeTextSearch = '';
      // I should move this to a web worker
      const filteredPeeps = filterAndMapPeeps(
        newState.unfilteredPeeps,
        newState
      );
      newState.projectsAndPeeps = filteredPeeps.projectsAndPeeps;
      newState.filteredPeepRows = filteredPeeps.filteredPeepRows;
      break;
    case DispatchType.SetSquadResponse:
      newState.squads = action.squads;
      break;
    case DispatchType.SetBookingsResponse:
      newState.lastUpdated = Date.now();
      newState.isLoadingData = false;
      newState.hasErrors = false;
      const peeps = transformBookingsToPeeps(action.unfilteredPeeps);
      const bookingsFilteredPeeps = filterAndMapPeeps(peeps, newState);
      newState.unfilteredPeeps = peeps;
      newState.projectsAndPeeps = bookingsFilteredPeeps.projectsAndPeeps;
      newState.filteredPeepRows = bookingsFilteredPeeps.filteredPeepRows;
      break;
    case DispatchType.SetIsLoading:
      newState.isLoadingData = action.isLoadingData;
      break;
    case DispatchType.SetHasErrors:
      newState.hasErrors = action.hasErrors;
      break;
  }

  return newState;
};
