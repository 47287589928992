import React, { CSSProperties, useState } from 'react';
import { makeStyles, Typography, Theme } from '@material-ui/core';
import { COL_HEADER_WIDTH, ROW_HEIGHT, COLUMN_WIDTH } from './GridDefaults';
import { ProjectForecaster } from '../ProjectForecaster';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import { Booking } from '../../services/models';
import { blueGrey } from '@material-ui/core/colors';
import { UNASSIGNED_ROLE } from '../../services/mapper';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    width: COL_HEADER_WIDTH + COLUMN_WIDTH,
    minHeight: ROW_HEIGHT,
    position: 'sticky',
    zIndex: 9,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    boxShadow: '2px 0px 2px hsla(0,0%,0%,0.1)',
    cursor: 'pointer',
    border: `solid ${blueGrey[200]}`,
    borderLeftWidth: 0,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 1,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
  },
  subtitle: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  planeIcon: {
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'middle',
    transform: 'rotate(90deg)',
    width: '0.65em',
  },
  unassignedIcon: {
    marginRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.25),
    verticalAlign: 'middle',
    width: '0.65em',
  },
}));

interface UserHeaderProps {
  givenName: string;
  familyName: string;
  bookings: Booking[];
  interSquad?: string | undefined;
  positionTitle: string;
  style: CSSProperties;
}

const GridUserHeader: React.FC<UserHeaderProps> = ({
  givenName,
  familyName,
  positionTitle,
  interSquad,
  bookings,
  style,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const openForecaster = event => setAnchorEl(event.currentTarget);
  const openEarmarker = () => console.log('Earmarker not implemented');
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <div
        className={classes.cell}
        style={style}
        onClick={givenName !== UNASSIGNED_ROLE ? openForecaster : openEarmarker}
      >
        <Typography
          component="span"
          variant="subtitle2"
          className={classes.subtitle}
        >
          {givenName} {familyName}
        </Typography>

        {givenName === UNASSIGNED_ROLE && (
          <AssignmentLateIcon className={classes.unassignedIcon} />
        )}
        {interSquad && <AirplanemodeActiveIcon className={classes.planeIcon} />}
        <Typography component="p" variant="caption">
          {positionTitle}
        </Typography>
      </div>
      <ProjectForecaster
        handleClose={handleClose}
        anchorEl={anchorEl}
        {...{ givenName, familyName, interSquad, bookings }}
      />
    </>
  );
};

export default GridUserHeader;
