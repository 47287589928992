import React from 'react';
import { Typography, Button } from '@material-ui/core';

class ErrorBoundary extends React.Component<
  { large?: boolean },
  { hasError: boolean; error: Error | undefined }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ textAlign: 'center' }}>
          {this.props.large && (
            <>
              <Typography variant="h4">Something went wrong!</Typography>
              <Typography variant="subtitle1">
                {this.state.error?.name}
                {this.state.error?.message}
              </Typography>
            </>
          )}
          {!this.props.large && (
            <Typography variant="subtitle2">Error!</Typography>
          )}
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Try again?
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
