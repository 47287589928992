import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import { COLUMNS } from '../components/Grid/GridDefaults';

export enum BookingType {
  Soft = 'Soft',
}

export const getBookingVariables = squadId => {
  const today = DateTime.local();
  const defaultStartDate = today.startOf('week').toFormat('yyyy-MM-dd');
  const defaultEndDate = today
    .endOf('week')
    .plus({ weeks: COLUMNS })
    .toFormat('yyyy-MM-dd');

  return {
    variables: {
      squadId: squadId,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  };
};

export const BOOKING_QUERY = gql`
  query Booking(
    $squadId: ID!
    $bookingTypes: [BookingType!]
    $startDate: Date!
    $endDate: Date!
  ) {
    squad(id: $squadId) {
      id
      name
      people {
        id
        givenName
        familyName
        positionTitle
        department
        professionalDevelopment(startDate: $startDate, endDate: $endDate) {
          startDate
          endDate
          hours
          status
          source
          type
        }
        leave(startDate: $startDate, endDate: $endDate) {
          startDate
          endDate
          hours
          status
          source
          type
        }
        publicHolidays(startDate: $startDate, endDate: $endDate) {
          startDate
          endDate
          hours
          name
        }
      }
      bookings(startDate: $startDate, endDate: $endDate, types: $bookingTypes) {
        type
        startDate
        endDate
        project {
          id
          name
          salesRepresentative {
            ... on PurplePerson {
              name
            }
            ... on NonPurplePerson {
              name
            }
          }
          customer {
            id
            name
          }
        }
        bookingsByDay {
          id
          hours
          date
        }
        against {
          ... on PurplePerson {
            id
            givenName
            familyName
            positionTitle
            department
            squad {
              id
              name
            }
          }
          ... on NonPurplePerson {
            id
            givenName
            familyName
            email
          }
          ... on Placeholder {
            roleName
          }
        }
      }
    }
  }
`;

export const SQUAD_LIST_QUERY = gql`
  query GetSquads {
    squads {
      id
      name
    }
  }
`;
