import { Peep } from './models';

const FILTER_VALUE_SPLIT_CHARACTER = ',';

type FilterPrefix = keyof typeof prefixes;
type FilterWithFunc = [string, (peeps: Peep[], filter: string) => Peep[]];

interface KeyedFilter {
  key: FilterPrefix;
  filter: string;
}

const filterByName = (peeps: Peep[], filter: string): Peep[] => {
  const filteredPeeps: Peep[] = [];
  const peepNames = filter.split(FILTER_VALUE_SPLIT_CHARACTER);
  for (const peepName of peepNames) {
    const regExp = createCaseInsensitiveRegExp(peepName);
    for (const peep of peeps) {
      const name = `${peep.givenName} ${peep.familyName}`;
      if (regExp.test(name) && !filteredPeeps.includes(peep)) {
        filteredPeeps.push(peep);
      }
    }
  }

  return filteredPeeps;
};

const filterByRole = (peeps: Peep[], filter: string): Peep[] => {
  const filteredPeeps: Peep[] = [];
  const roleNames = filter.split(FILTER_VALUE_SPLIT_CHARACTER);
  for (const roleName of roleNames) {
    const regExp = createCaseInsensitiveRegExp(roleName);
    for (const peep of peeps) {
      if (regExp.test(peep.positionTitle) && !filteredPeeps.includes(peep)) {
        filteredPeeps.push(peep);
      }
    }
  }

  return filteredPeeps;
};

const filterByProject = (peeps: Peep[], filter: string): Peep[] => {
  const filteredPeeps: Peep[] = [];
  const projectNames = filter.split(FILTER_VALUE_SPLIT_CHARACTER);
  for (const projectName of projectNames) {
    const regExp = createCaseInsensitiveRegExp(projectName);
    for (const peep of peeps) {
      for (const booking of peep.bookings) {
        if (
          regExp.test(booking.project.name) &&
          !filteredPeeps.includes(peep)
        ) {
          filteredPeeps.push(peep);
          break;
        }
      }
    }
  }

  return filteredPeeps;
};

const filterByClient = (peeps: Peep[], filter: string): Peep[] => {
  const filteredPeeps: Peep[] = [];
  const clientNames = filter.split(FILTER_VALUE_SPLIT_CHARACTER);
  for (const clientName of clientNames) {
    const regExp = createCaseInsensitiveRegExp(clientName);
    for (const peep of peeps) {
      for (const booking of peep.bookings) {
        if (
          booking.project.customer?.name &&
          regExp.test(booking.project.customer.name) &&
          !filteredPeeps.includes(peep)
        ) {
          filteredPeeps.push(peep);
          break;
        }
      }
    }
  }

  return filteredPeeps;
};

const filterBySalesRep = (peeps: Peep[], filter: string): Peep[] => {
  const filteredPeeps: Peep[] = [];
  const clientNames = filter.split(FILTER_VALUE_SPLIT_CHARACTER);
  for (const clientName of clientNames) {
    const regExp = createCaseInsensitiveRegExp(clientName);
    for (const peep of peeps) {
      for (const booking of peep.bookings) {
        if (
          booking.project.salesRepresentative?.name &&
          regExp.test(booking.project.salesRepresentative.name) &&
          !filteredPeeps.includes(peep)
        ) {
          filteredPeeps.push(peep);
          break;
        }
      }
    }
  }

  return filteredPeeps;
};

const filterByInterSquad = (peeps: Peep[], filter: string): Peep[] => {
  const filteredPeeps: Peep[] = [];
  const interSquadNames = filter.split(FILTER_VALUE_SPLIT_CHARACTER);
  for (const interSquadName of interSquadNames) {
    const regExp = createCaseInsensitiveRegExp(interSquadName);
    for (const peep of peeps) {
      if (
        peep.interSquad &&
        regExp.test(peep.interSquad) &&
        !filteredPeeps.includes(peep)
      ) {
        filteredPeeps.push(peep);
      }
    }
  }

  return filteredPeeps;
};

const filterByCapability = (peeps: Peep[], filter: string): Peep[] => {
  const filteredPeeps: Peep[] = [];
  const capabilityNames = filter.split(FILTER_VALUE_SPLIT_CHARACTER);
  for (const capabilityName of capabilityNames) {
    for (const peep of peeps) {
      const regExp = createCaseInsensitiveRegExp(capabilityName);
      if (
        peep.department &&
        regExp.test(peep.department) &&
        !filteredPeeps.includes(peep)
      ) {
        filteredPeeps.push(peep);
      }
    }
  }

  return filteredPeeps;
};

const filterByTask = (peeps: Peep[], filter: string): Peep[] => {
  // TODO: Determine whether or not we can implement this filter, given the
  // data we have from Purple Graph does not currently include the task.
  return peeps;
};

const filterByProjectCoordinator = (peeps: Peep[], filter: string): Peep[] => {
  // TODO: Determine whether or not we can implement this filter, given the
  // data we have from Purple Graph does not currently include the coordinator.
  return peeps;
};

const prefixes = {
  co: ['consultant name', filterByName] as FilterWithFunc,
  r: ['role name', filterByRole] as FilterWithFunc,
  pr: ['project name', filterByProject] as FilterWithFunc,
  cl: ['client name', filterByClient] as FilterWithFunc,
  t: ['task name', filterByTask] as FilterWithFunc,
  s: ['sales rep name', filterBySalesRep] as FilterWithFunc,
  is: ['inter-squad', filterByInterSquad] as FilterWithFunc,
  ca: ['capability', filterByCapability] as FilterWithFunc,
  pc: [
    'project coordinator name',
    filterByProjectCoordinator,
  ] as FilterWithFunc,
};

const getPeepsFilteredByText = (
  filterText: string,
  allPeeps: Peep[]
): Peep[] => {
  if (!allPeeps) {
    return [];
  }

  if (!filterText || !(filterText = filterText.trim())) {
    return allPeeps;
  }

  const filterParts = getFilterParts(filterText);
  if (filterParts.length === 0) {
    // It's a generic filter with no particular fields specified.
    const regExp = createCaseInsensitiveRegExp(filterText);
    return allPeeps.filter(peep => {
      const fullName = `${peep.givenName} ${peep.familyName}`;
      return (
        regExp.test(fullName) ||
        (peep.department && regExp.test(peep.department)) ||
        regExp.test(peep.positionTitle) ||
        (peep.interSquad && regExp.test(peep.interSquad)) ||
        peep.bookings.some(booking => regExp.test(booking.project.name)) ||
        peep.bookings.some(
          booking =>
            booking.project.customer &&
            regExp.test(booking.project.customer.name)
        ) ||
        peep.bookings.some(
          booking =>
            booking.project.salesRepresentative &&
            regExp.test(booking.project.salesRepresentative.name)
        )
      );
    });
  }

  let filteredPeeps = allPeeps;
  for (const keyedFilter of filterParts) {
    const filterFunc = prefixes[keyedFilter.key][1];
    filteredPeeps = filterFunc(filteredPeeps, keyedFilter.filter);
  }

  return filteredPeeps;
};

const getFilterParts = (filterText: string): KeyedFilter[] => {
  const filterParts: KeyedFilter[] = [];
  for (const prefix in prefixes) {
    const searchText = prefix + ':';
    let startIndex = -1;
    let isFound = false;
    do {
      startIndex = filterText.indexOf(searchText, startIndex + 1);
      if (
        startIndex >= 0 &&
        (startIndex === 0 || filterText[startIndex - 1] === ' ')
      ) {
        isFound = true;
      }
    } while (startIndex >= 0 && !isFound);

    if (isFound) {
      const endIndex = getEndIndex(filterText, startIndex + searchText.length);
      filterParts.push({
        key: prefix as FilterPrefix,
        filter: filterText
          .substring(startIndex + searchText.length, endIndex)
          .trim(),
      });
    }
  }

  return filterParts;
};

const getEndIndex = (filterText: string, startIndex: number): number => {
  let endIndex = filterText.length;
  for (const prefix in prefixes) {
    const searchText = prefix + ':';
    const nextPrefixIndex = filterText.indexOf(searchText, startIndex);
    if (nextPrefixIndex >= 0) {
      endIndex = Math.min(nextPrefixIndex, endIndex);
    }
  }

  return endIndex;
};

const sanitizeForRegExp = (text: string): string => {
  // Remove anything except word characters (\w) and spaces (\s) so that we
  // can safely make a RegExp out of it.
  const sanitizedText = text.replace(/[^\w\s]/gi, '');
  return sanitizedText;
};

const createCaseInsensitiveRegExp = (text: string): RegExp => {
  const regExp = new RegExp(sanitizeForRegExp(text).trim(), 'ig');
  return regExp;
};

export default getPeepsFilteredByText;
