import React from 'react';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { DateTime } from 'luxon';
import { CircularProgress, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { AppState } from '../services/appReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
      minWidth: theme.spacing(24),
      padding: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
    },
    icon: {
      marginTop: '4px',
    },
    warningIcon: {
      marginTop: '4px',
      color: 'red',
    },
    spinner: {
      marginLeft: '10px',
    },
  })
);

type LastUpdatedPopUpProps = Pick<
  AppState,
  'lastUpdated' | 'hasErrors' | 'isLoadingData'
>;

const LastUpdatedPopUp: React.FC<LastUpdatedPopUpProps> = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { lastUpdated, hasErrors, isLoadingData } = props;
  let formattedDate = '';
  if (lastUpdated) {
    formattedDate = DateTime.fromMillis(lastUpdated).toFormat(
      'dd/MM/yyyy hh:mm a'
    );
  }

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        style={{ marginLeft: '10px' }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onMouseDown={handlePopoverOpen}
      >
        {isLoadingData ? (
          <CircularProgress size={32} className={classes.spinner} />
        ) : hasErrors ? (
          <ErrorOutlineIcon className={classes.warningIcon} />
        ) : (
          <CheckCircleOutlineIcon className={classes.icon} />
        )}
      </div>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="body2">
          <i>
            <b>{hasErrors ? 'Error occurred while loading data.' : ''}</b>
          </i>
        </Typography>
        <Typography variant="body2">
          <b>Last Updated</b> <br /> {formattedDate}
        </Typography>
      </Popover>
    </div>
  );
};

export default LastUpdatedPopUp;
