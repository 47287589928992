import React, { useMemo } from 'react';
import {
  COLUMN_WIDTH,
  COLUMNS,
  COL_HEADER_WIDTH,
  HEADER_HEIGHT,
} from './GridDefaults';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import TodayHighlight from './TodayHighlight';
import { blueGrey } from '@material-ui/core/colors';

const useWeekStyles = makeStyles(() => ({
  week: {
    display: 'grid',
    gridTemplate: '1fr / repeat(5, 1fr)',
    minWidth: COLUMN_WIDTH,
    width: COLUMN_WIDTH,
    position: 'relative',
    border: `solid ${blueGrey[200]}`,
    borderLeftWidth: 0,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 1,
  },
  day: {
    textAlign: 'center',
    padding: '0.5rem',
  },
}));

const daysFromIndex = (
  index: number,
  classes: Record<'week' | 'day', string>
) => {
  const startOfWeek = DateTime.local()
    .plus({ weeks: index })
    .startOf('week');

  const weekDays = [
    startOfWeek,
    startOfWeek.plus({ days: 1 }),
    startOfWeek.plus({ days: 2 }),
    startOfWeek.plus({ days: 3 }),
    startOfWeek.plus({ days: 4 }),
  ];

  return weekDays.map((day, index) => (
    <div key={index} className={classes.day}>
      <Typography variant="subtitle2"> {day.toFormat('dd/MM')} </Typography>
      <Typography variant="caption"> {day.weekdayShort} </Typography>
    </div>
  ));
};

const WeekHeader: React.FC<{ index: number }> = ({ index }) => {
  const classes = useWeekStyles();
  const days = useMemo(() => daysFromIndex(index, classes), [index, classes]);
  return (
    <div className={classes.week}>
      {index === 0 && <TodayHighlight />}
      {days}
    </div>
  );
};

const useStickyContextStyles = makeStyles((theme: Theme) => ({
  topRow: {
    position: 'sticky',
    display: 'flex',
    height: HEADER_HEIGHT,
    top: 0,
    zIndex: 12,
    paddingLeft: 200,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    boxShadow: '2px 2px 2px hsla(0,0%,0%,0.1)',
    width: COLUMNS * COLUMN_WIDTH + COL_HEADER_WIDTH,
  },
}));

const GridTopRow = () => {
  const classes = useStickyContextStyles();
  const cols = [] as number[];

  for (let i = 0; i < COLUMNS; i++) {
    cols.push(i);
  }

  return (
    <div className={classes.topRow}>
      {cols.map(index => (
        <WeekHeader index={index} key={index} />
      ))}
    </div>
  );
};

export default GridTopRow;
