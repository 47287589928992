import React from 'react';
import {
  FormControl,
  Button,
  FormLabel,
  ButtonGroup,
  makeStyles,
  createStyles,
} from '@material-ui/core';

export interface ButtonGroupProps {
  label: string;
  value: string;
  options: string[];
  onChange: (string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: '0.75rem',
      marginBottom: '0.4rem',
    },
    formGroup: {
      marginBottom: '0.4rem',
    },
  })
);

const ButtonGroupSelect: React.FC<ButtonGroupProps> = ({
  label,
  value,
  options,
  onChange,
}) => {
  const classes = useStyles();
  const handleChange = value => {
    onChange(value);
  };

  return (
    <FormControl className={classes.formGroup}>
      <FormLabel className={classes.label} component="legend">
        {label}
      </FormLabel>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        {options.map(option => (
          <Button
            size="small"
            key={option}
            variant={value === option ? 'contained' : 'outlined'}
            onClick={() => handleChange(option)}
          >
            {option}
          </Button>
        ))}
      </ButtonGroup>
    </FormControl>
  );
};

export default ButtonGroupSelect;
