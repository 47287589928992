import { makeStyles, createStyles, Button } from '@material-ui/core';
import React, { Dispatch } from 'react';
import { AppAction, DispatchType } from '../services/appReducer';

const useStyles = makeStyles(() =>
  createStyles({
    clearAll: {
      width: '100%',
      marginTop: '1rem', // sets itself apart a bit
      marginBottom: '0.5rem',
    },
  })
);

const ClearAllButton: React.FC<{
  text?: string;
  dispatch: Dispatch<AppAction>;
}> = ({ text, dispatch }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.clearAll}
      onClick={() => dispatch({ type: DispatchType.ResetFilter })}
    >
      {text ? text : 'Clear All'}
    </Button>
  );
};

export default ClearAllButton;
