import React, { memo } from 'react';
import ProjectViewCell from './ProjectViewCell';
import AutosizedVirtualGrid from '../Grid/AutosizedVirtualGrid';
import { ROW_HEIGHT, ROW_PADDING } from '../Grid/GridDefaults';
import ColumnHeaders from './ColumnHeaders';
import { StateProps } from '../../App';

const ProjectsView = (props: StateProps) => {
  const { lastUpdated, isLoadingData, projectsAndPeeps } = props.state;

  const CellMemo = memo(
    ProjectViewCell,
    (oldProps, { columnIndex, rowIndex, isScrolling }) =>
      oldProps.columnIndex === columnIndex &&
      oldProps.rowIndex === rowIndex &&
      isScrolling
  );

  // TODO - I feel like this may have performance implications, read the doco and do some tests.
  const rowHeight = r => {
    if (!projectsAndPeeps) {
      return ROW_HEIGHT;
    }
    const row = projectsAndPeeps[r];
    if ('overlappingBookings' in row) {
      const overlap = row.overlappingBookings > 0 ? row.overlappingBookings : 1;

      return overlap * ROW_HEIGHT + ROW_PADDING * 2;
    } else {
      return ROW_HEIGHT;
    }
  };

  return (
    <AutosizedVirtualGrid
      dispatch={props.dispatch}
      data={projectsAndPeeps}
      lastUpdate={lastUpdated}
      rowHeight={rowHeight}
      Cell={CellMemo}
      StickyColumn={ColumnHeaders}
      isLoadingData={isLoadingData}
    />
  );
};

export default ProjectsView;
