import React from 'react';
import { DateTime } from 'luxon';
import { COLUMN_WIDTH, HEADER_HEIGHT, COL_HEADER_WIDTH } from './GridDefaults';
import { makeStyles, fade } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import clsx from 'clsx';

const dayOfWeek = DateTime.local().weekday;
const offset = COL_HEADER_WIDTH + ((dayOfWeek - 1) / 5) * COLUMN_WIDTH;

const useStyles = makeStyles(() => ({
  todayHighlight: {
    pointerEvents: 'none',
    position: 'absolute',
    left: ((dayOfWeek - 1) / 5) * COLUMN_WIDTH,
    backgroundColor: fade(deepPurple[400], 0.2),
    width: COLUMN_WIDTH / 5,
    height: '100%',
    zIndex: 5,
  },
  fixed: {
    left: offset,
    top: HEADER_HEIGHT,
  },
}));

export interface TodayHighlightProps {
  height?: string | number | undefined;
  isFixed?: boolean;
}

const TodayHighlight: React.FC<TodayHighlightProps> = ({
  height = '100%',
  isFixed = false,
}) => {
  const { todayHighlight, fixed } = useStyles();
  const style = { height };
  return (
    <div
      className={clsx(todayHighlight, { [fixed]: isFixed })}
      style={isFixed ? style : undefined}
    ></div>
  );
};

export default TodayHighlight;
