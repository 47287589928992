import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Paper,
  Popover,
  FormControl,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Booking } from '../services/models';
import { DateTime } from 'luxon';
import truncate from 'lodash/truncate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      backgroundColor: theme.palette.background.default,
      minWidth: theme.spacing(24),
      padding: theme.spacing(2),
    },
    appbar: {
      backgroundColor: theme.palette.background.default,
      margin: theme.spacing(1, 0),
    },
    toggle: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1.2),
      minWidth: 110,
    },
    input: {
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    textField: {
      width: 110,
    },
    calculateButton: {
      width: '100%',
      margin: theme.spacing(2, 0),
    },
  })
);

interface ProjectForecasterProps {
  bookings: Booking[];
  givenName: string;
  familyName: string;
  interSquad?: string;
  anchorEl: any;
  handleClose: () => void;
}

export enum ForecastMode {
  duration = 'duration',
  endDate = 'endDate',
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: ForecastMode;
  value: ForecastMode;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <>{children}</>}
    </div>
  );
};

export interface SkippedDay {
  day: string;
  reason: string;
  hours: number;
}

export const calculateDuration = (
  bookings: Booking[],
  startDate: DateTime,
  endDate: DateTime
) =>
  calculateDurationOrEndDate(
    ForecastMode.duration,
    bookings,
    startDate,
    endDate,
    0
  );

export const calculateEndDate = (
  bookings: Booking[],
  startDate: DateTime,
  duration: number
) =>
  calculateDurationOrEndDate(
    ForecastMode.endDate,
    bookings,
    startDate,
    startDate,
    duration
  );

const calculateDurationOrEndDate = (
  mode: ForecastMode,
  bookings: Booking[],
  startDate: DateTime,
  endDate: DateTime,
  duration: number
) => {
  const skippedDays: SkippedDay[] = [];

  let totalHours = 0;
  let currentDate = startDate;

  while (
    (mode === ForecastMode.duration && endDate >= currentDate) ||
    (mode === ForecastMode.endDate && totalHours < duration)
  ) {
    if (currentDate.weekday === 6 || currentDate.weekday === 7) {
      currentDate = currentDate.plus({ days: 1 });
      continue;
    }

    const currentDateString = currentDate.toFormat('yyyy-MM-dd');
    const hoursBookedToday = bookings.reduce(
      (totalHours: number, booking: Booking) => {
        const bookingsByDay = booking.bookingsByDay.filter(
          bookingForDay => bookingForDay.date === currentDateString
        );

        return (
          totalHours +
          bookingsByDay.reduce((hoursForBooking, bookingForDay) => {
            skippedDays.push({
              day: DateTime.fromISO(bookingForDay.date).toFormat('dd/MM'),
              reason: booking.project.name,
              hours: bookingForDay.hours,
            });
            return hoursForBooking + bookingForDay.hours;
          }, 0)
        );
      },
      0
    );

    const hoursToAdd = Math.max(0, 8 - hoursBookedToday);
    totalHours += hoursToAdd;
    if (mode === ForecastMode.duration || totalHours < duration) {
      currentDate = currentDate.plus({ days: 1 });
    }
  }

  return {
    endDate: mode === ForecastMode.endDate ? currentDate : undefined,
    duration: mode === ForecastMode.duration ? totalHours : undefined,
    skippedDays: skippedDays,
  };
};

export const ProjectForecaster: React.FC<ProjectForecasterProps> = ({
  bookings,
  givenName,
  familyName,
  interSquad,
  anchorEl,
  handleClose,
}) => {
  const classes = useStyles();
  const popupOpen = Boolean(anchorEl);
  const [mode, setMode] = useState<ForecastMode>(ForecastMode.endDate);
  const [selectedStartDate, setSelectedStartDate] = useState(DateTime.local());
  const [selectedEndDate, setSelectedEndDate] = useState(DateTime.local());
  const [selectedDuration, setSelectedDuration] = useState<number>();

  const [calculatedEndDate, setCalculatedEndDate] = useState<DateTime>();
  const [calculatedDuration, setCalculatedDuration] = useState<number>();
  const [skippedDays, setSkippedDays] = useState<SkippedDay[]>();

  const resetState = () => {
    setCalculatedEndDate(undefined);
    setCalculatedDuration(undefined);
    setSkippedDays(undefined);
  };

  const popoverClosed = () => {
    resetState();
    handleClose();
  };

  const handleStartDateChange = date => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = date => {
    setSelectedEndDate(date);
  };

  const handleDurationChange = event => {
    setSelectedDuration(event.target.value);
  };

  const handleModeChange = (_, newMode: ForecastMode) => {
    resetState();
    setMode(newMode);
  };

  const [isDays, setIsDays] = useState(true);
  const handleToggleChange = () => {
    setIsDays(!isDays);
  };

  const calculate = () => {
    const { endDate, duration, skippedDays } = calculateDurationOrEndDate(
      mode,
      bookings,
      selectedStartDate,
      selectedEndDate,
      (selectedDuration || 0) * (isDays ? 8 : 1)
    );

    setSkippedDays(skippedDays);
    setCalculatedEndDate(endDate);
    setCalculatedDuration(duration);
  };

  return (
    <Popover
      open={popupOpen}
      anchorEl={anchorEl}
      onClose={popoverClosed}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Paper className={classes.popover}>
        <Typography variant="h6">Project Date Forecaster</Typography>
        <Typography variant="caption">
          for: {givenName} {familyName} {interSquad ? `(${interSquad})` : ''}
        </Typography>

        <AppBar position="static" variant="outlined" className={classes.appbar}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={mode}
            onChange={handleModeChange}
          >
            <Tab label="End Date" value={ForecastMode.endDate} />
            <Tab label="Duration" value={ForecastMode.duration} />
          </Tabs>
        </AppBar>

        <FormControl className={classes.input}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="start-date"
            label="Start Date"
            value={selectedStartDate}
            onChange={handleStartDateChange}
          />
        </FormControl>

        <TabPanel value={mode} index={ForecastMode.endDate}>
          <FormControl className={classes.input}>
            <TextField
              id="duration"
              label="Duration"
              type="number"
              className={classes.textField}
              value={selectedDuration || ''}
              onChange={handleDurationChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControlLabel
              className={classes.toggle}
              control={
                <Switch
                  checked={isDays}
                  onChange={handleToggleChange}
                  value="checked"
                  color="default"
                />
              }
              labelPlacement="start"
              label={isDays ? 'Days' : 'Hours'}
            />
          </FormControl>
        </TabPanel>
        <TabPanel value={mode} index={ForecastMode.duration}>
          <FormControl className={classes.input}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="end-date"
              label="End Date"
              value={selectedEndDate}
              onChange={handleEndDateChange}
            />
          </FormControl>
        </TabPanel>

        <Button
          className={classes.calculateButton}
          variant="contained"
          color="primary"
          onClick={calculate}
        >
          Calculate
        </Button>

        {calculatedEndDate && (
          <Typography variant="caption" component="div">
            Earliest possible end date:{' '}
            <b>{calculatedEndDate.toLocaleString()}</b>
          </Typography>
        )}
        {calculatedDuration !== undefined && (
          <Typography variant="caption" component="div">
            Duration:{' '}
            <b>
              {calculatedDuration} hours ({calculatedDuration / 8} days)
            </b>
          </Typography>
        )}
        {skippedDays && (
          <>
            <Typography variant="caption" component="div">
              Skipped bookings ({skippedDays.length})
            </Typography>

            <div style={{ maxHeight: 230, overflowX: 'auto' }}>
              {skippedDays.map((skippedDay, i) => (
                <Typography key={i} variant="caption" component="li">
                  {skippedDay.day}:{' '}
                  {truncate(skippedDay.reason, { length: 21 })} [
                  {skippedDay.hours} hours]
                </Typography>
              ))}
            </div>
          </>
        )}
      </Paper>
    </Popover>
  );
};
