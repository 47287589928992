import React, { Dispatch, useEffect, useReducer, useState } from 'react';
import {
  makeStyles,
  createStyles,
  CssBaseline,
  Grid,
  CircularProgress,
  ThemeProvider,
  ThemeOptions,
  createMuiTheme,
  Theme,
} from '@material-ui/core';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { ApolloProvider, useLazyQuery, useQuery } from '@apollo/client';
import { ReactComponent as Logo } from './assets/pedro-nametag.svg';
import ErrorBoundary from './components/ErrorBoundary';
import Main from './components/Main';
import { useAccount } from './services/auth';
import { purpleGraphClient } from './services/purpleGraph';
import Header from './components/Header';
import {
  AppAction,
  appReducer,
  AppState,
  DispatchType,
} from './services/appReducer';
import {
  BOOKING_QUERY,
  getBookingVariables,
  SQUAD_LIST_QUERY,
} from './services/queries';

export interface StateProps {
  state: AppState;
  dispatch: Dispatch<AppAction>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100vw',
      position: 'fixed',
    },
    logo: {
      fill: '#000',
      top: 'calc(50% - 75px)',
      left: 'calc(50% - 75px)',
      width: '150px',
      height: '150px',
      position: 'absolute',
    },
    progress: {
      top: 'calc(50% - 128px)',
      left: 'calc(50% - 128px)',
      position: 'absolute',
      color: '#000',
      strokeLinecap: 'round',
    },
    grid: {
      height: '100%',
    },
    loadingScreen: {
      width: '100%',
      height: '100%',
    },
    main: {
      background: theme.palette.background.default,
      flexGrow: 1,
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      position: 'relative',
    },
  })
);

const BigSpinner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.loadingScreen}>
      <CircularProgress className={classes.progress} size={256} thickness={2} />
      <Logo className={classes.logo} />
    </div>
  );
};

export const AuthContext = React.createContext<any | undefined>(undefined);

const PureApp: React.FC<{ onThemeChanged: (themeName: string) => void }> = ({
  onThemeChanged,
}) => {
  const classes = useStyles();
  const initialState = new AppState();
  const [appState, dispatch] = useReducer(appReducer, initialState);
  const DEV_DATA = process.env.REACT_APP_DEV_DATA === 'true';

  const [getBookings, { loading: isLoadingBookings }] = useLazyQuery(
    BOOKING_QUERY,
    {
      onCompleted: bookings => {
        dispatch({
          type: DispatchType.SetBookingsResponse,
          unfilteredPeeps: bookings.squad,
        });
      },
      onError: error => {
        dispatch({ type: DispatchType.SetHasErrors, hasErrors: true });
      },
    }
  );

  const { loading: isLoadingSquads } = useQuery(SQUAD_LIST_QUERY, {
    skip: DEV_DATA,
    onCompleted: response => {
      dispatch({
        type: DispatchType.SetSquadResponse,
        squads: response.squads,
      });

      getBookings(getBookingVariables(appState.squadId));
    },
    onError: error => {
      // TODO add error handling, right now we will continue showing loading spinner after the error returns.
      dispatch({ type: DispatchType.SetHasErrors, hasErrors: true });
    },
  });

  // Get bookings for new squad ID
  useEffect(() => {
    getBookings(getBookingVariables(appState.squadId));
  }, [appState.squadId, getBookings]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        wrap="nowrap"
        className={classes.grid}
      >
        <Header
          onThemeChanged={onThemeChanged}
          state={appState}
          dispatch={dispatch}
        />
        <main className={classes.main}>
          <ErrorBoundary large>
            {!appState.isLoadingData &&
            !isLoadingBookings &&
            !isLoadingSquads ? (
              <Main state={appState} dispatch={dispatch} />
            ) : (
              <BigSpinner />
            )}
          </ErrorBoundary>
        </main>
      </Grid>
    </div>
  );
};

const commonThemeProps: ComponentsProps = {
  MuiFormControl: {
    margin: 'dense',
    variant: 'outlined',
  },
  MuiTextField: {
    margin: 'dense',
    variant: 'outlined',
  },
  MuiButton: {
    variant: 'outlined',
  },
};

const lightThemeProps: ThemeOptions = {
  props: commonThemeProps,
  palette: {
    type: 'light',
  },
};

const darkThemeProps: ThemeOptions = {
  props: commonThemeProps,
  palette: {
    type: 'dark',
    primary: {
      main: '#99F',
    },
  },
};

const App = () => {
  const account = useAccount();
  const [isDarkMode, setIsDarkMode] = useState(false);
  return (
    <AuthContext.Provider value={account}>
      <ApolloProvider client={purpleGraphClient}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <ThemeProvider
            theme={
              isDarkMode
                ? createMuiTheme(darkThemeProps)
                : createMuiTheme(lightThemeProps)
            }
          >
            <CssBaseline />
            {account && (
              <PureApp
                onThemeChanged={themeName =>
                  setIsDarkMode(themeName === 'dark')
                }
              />
            )}
            {!account && <BigSpinner />}
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export default App;
