import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { acquireToken } from './auth';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

export const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

const scope = `${process.env.REACT_APP_PURPLE_GRAPH_BASE_URL}/PurpleGraph.Access`;

const uri = `${process.env.REACT_APP_PURPLE_GRAPH_BASE_URL}/graphql`;

export const scopes = [scope];

const httpLink = createHttpLink({ uri });

const authLink = setContext(async (_, { headers }) => {
  const authResponse = await acquireToken(scopes);
  return {
    headers: {
      ...headers,
      authorization: authResponse ? `Bearer ${authResponse.accessToken}` : '',
    },
  };
});

export const purpleGraphClient = new ApolloClient({
  link: link.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
});
