import React from 'react';
import BookingView from './Booking/BookingView';
import ProjectsView from './Project/ProjectView';
import { ViewMode } from '../hooks/useFilterReducer';
import { StateProps } from '../App';

const Main = (props: StateProps) => {
  return props.state.viewMode === ViewMode.Booking ? (
    <BookingView {...props} />
  ) : (
    <ProjectsView {...props} />
  );
};

export default Main;
