import React, { CSSProperties } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { blueGrey, grey } from '@material-ui/core/colors';
import {
  ROW_HEIGHT,
  HEADER_HEIGHT,
  COLUMN_WIDTH,
  COL_HEADER_WIDTH,
} from '../Grid/GridDefaults';
import { PeepRow } from '../../services/mapper';
import { PeepProject } from '../../services/models';
import BookingCell from '../Grid/BookingCell';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      height: ROW_HEIGHT + 1.5,
      width: COLUMN_WIDTH + 1.5,
      marginRight: -1.5,
      marginTop: -1.5,
      borderTop: `1px solid ${blueGrey[200]}`,
      borderBottom: `1px solid ${blueGrey[200]}`,
      backgroundColor: theme.palette.background.default,
      boxShadow: `3px 4px 4px hsla(0, 0%, 0%, 0.3)`,
      zIndex: 9,
    },
    cell: {
      color: theme.palette.getContrastText(grey[100]),
      display: 'flex',
      border: 'solid hsl(0,0%,60%)',
      alignItems: 'center',
      borderLeftWidth: '0',
      borderBottomWidth: '0',
      borderTopWidth: '1px',
      borderRightWidth: '0',
    },
  })
);

interface ProjectCellProps {
  data: (PeepRow | PeepProject)[];
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  isScrolling: boolean;
}

const ProjectViewCell: React.FC<ProjectCellProps> = props => {
  const { rowIndex, columnIndex, data, ...rest } = props;
  const classes = useStyles();
  const row = data[rowIndex];

  if ('weeks' in row) {
    const { bookingRanges } = row.weeks[columnIndex];
    return <BookingCell {...{ ...rest, bookingRanges }} />;
  } else {
    const top = (rest.style?.top as number) + HEADER_HEIGHT;
    const left = (rest.style?.left as number) + COL_HEADER_WIDTH;
    const style = { ...rest.style, top, left, height: undefined };
    return <div className={classes.spacer} style={style}></div>;
  }
};

export default ProjectViewCell;
