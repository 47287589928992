import { Peep, PeepProject, isBooking } from './models';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import {
  isUnassignedOnDay,
  PeepRow,
  peepsToPeepRows,
  UNASSIGNED_ROLE,
} from './mapper';
import { BookingType } from '../hooks/useFilterReducer';

export const NO_PROJECTS = { id: '0', name: 'No Projects' };

type SortedByBookingDatePeeps = {
  peep: Peep;
  closestBookingDate: Date | null;
};

export const sortedPeepsByBookingDate = (
  peeps: Peep[],
  projectId: string
): SortedByBookingDatePeeps[] => {
  const sortedByGivenName = peeps.sort((a, b) =>
    a.givenName.localeCompare(b.givenName)
  );
  const peepsWithClosestDate = sortedByGivenName.map(peep => {
    const filteredBookings = peep.bookings.filter(
      booking => booking.project.id === projectId
    );

    const bookingDaysFlattened = filteredBookings.flatMap(
      booking => booking.bookingsByDay
    );

    const sortedBookingDays = orderBy(
      bookingDaysFlattened,
      bookingDay => new Date(bookingDay.date),
      ['asc']
    );

    const currentMonday = DateTime.local()
      .startOf('week')
      .toJSDate();

    const closestBookingDay = sortedBookingDays.find(bookingDay => {
      return new Date(bookingDay.date) >= currentMonday;
    });
    const closestBookingDate = closestBookingDay
      ? new Date(closestBookingDay.date)
      : null;

    return {
      peep,
      closestBookingDate,
    };
  });

  return sortBy(peepsWithClosestDate, ['closestBookingDate']);
};

export const sortProjectAndPeeps = (
  peepsGroupedByProject: Record<string, ProjectAndPeeps>
): SortedByBookingDateProjects[] => {
  const projectsWithSortedPeeps: SortedByBookingDateProjects[] = Object.values(
    peepsGroupedByProject
  )
    .sort((a, b) => a.project.name.localeCompare(b.project.name))
    .map(({ peeps, project }) => {
      const sortedByBookingDatePeeps: SortedByBookingDatePeeps[] = sortedPeepsByBookingDate(
        peeps,
        project.id
      );
      return {
        project,
        closestBookingDate: sortedByBookingDatePeeps[0].closestBookingDate,
        sortedPeeps: sortedByBookingDatePeeps,
      };
    });

  return sortBy(projectsWithSortedPeeps, ['closestBookingDate']);
};

export const groupPeepsByProject = (
  peeps: Peep[]
): Record<string, ProjectAndPeeps> => {
  if (peeps.length === 0 || !peeps) {
    return {};
  }

  const projects: Record<string, ProjectAndPeeps> = {};
  const today = DateTime.local().toFormat('yyyy-MM-dd');
  peeps.forEach(peep => {
    if (peep.bookings.length !== 0) {
      peep.bookings.forEach(booking => {
        if (!isBooking(booking.type)) return;

        if (!projects[booking.project.id]) {
          projects[booking.project.id] = {
            project: booking.project,
            peeps: [],
          };
        }
        if (!projects[booking.project.id].peeps.find(x => x.id === peep.id))
          projects[booking.project.id].peeps.push(peep);
      });
    }

    if (peep.bookings.length === 0 || isUnassignedOnDay(peep, today)) {
      if (!projects[NO_PROJECTS.id]) {
        projects[NO_PROJECTS.id] = {
          project: NO_PROJECTS as PeepProject,
          peeps: [],
        };
      }
      projects[NO_PROJECTS.id].peeps.push(peep);
    }
  });

  return projects;
};

export interface SearchOptions {
  searchByRole: string[];
  searchByProject: string[];
  searchByConsultant: string[];
  searchByClient: string[];
  searchByCapability: string[];
  bookingTypes: BookingType[];
}

export const secondPassFilter = (
  peepsGroupedByProject: Record<string, ProjectAndPeeps>,
  {
    searchByRole,
    searchByProject,
    searchByConsultant,
    searchByClient,
    searchByCapability,
    bookingTypes,
  }: SearchOptions
): Record<string, ProjectAndPeeps> => {
  if (
    searchByRole.length === 0 &&
    searchByConsultant.length === 0 &&
    searchByClient.length === 0 &&
    searchByProject.length === 0 &&
    searchByCapability.length === 0 &&
    bookingTypes.length === 0
  ) {
    return peepsGroupedByProject;
  }

  const bookingTypeFilter = ({ project, peeps }: ProjectAndPeeps) => {
    if (
      bookingTypes.includes(BookingType.Soft) &&
      bookingTypes.includes(BookingType.Unassigned)
    ) {
      return peeps.some(peep => {
        return peep.bookings
          .filter(booking => booking.project.id === project.id)
          .some(booking => {
            if (booking.type === BookingType.Soft) return true;
            if (booking.type === BookingType.Unassigned) return true;
            return false;
          });
      });
    } else if (bookingTypes.includes(BookingType.Soft)) {
      return peeps.some(peep => {
        return peep.bookings
          .filter(booking => booking.project.id === project.id)
          .some(booking => {
            if (booking.type === BookingType.Soft) return true;
            return false;
          });
      });
    } else if (bookingTypes.includes(BookingType.Unassigned)) {
      /* We have 'peeps' that are unassigned roles within projects, 
      will probably need to refactor the model to make this easier to sort and filter */
      return peeps.some(peep => peep.givenName === UNASSIGNED_ROLE);
    }

    return false;
  };

  return Object.values(peepsGroupedByProject)
    .filter(({ project, peeps }) => {
      const matchesProject = searchByProject.includes(project.id);
      const matchesClient =
        project.customer?.id && searchByClient.includes(project.customer?.id);
      const matchesPeep = peeps.some(peep =>
        searchByConsultant.includes(peep.id)
      );
      const matchesRole = peeps.some(peep =>
        searchByRole.includes(peep.positionTitle)
      );
      const matchesCapability = peeps.some(peep =>
        searchByCapability.includes(peep.department || 'none')
      );

      const matchesBookingType = bookingTypeFilter({ project, peeps });

      return (
        matchesProject ||
        matchesClient ||
        matchesPeep ||
        matchesCapability ||
        matchesRole ||
        matchesBookingType
      );
    })
    .reduce(
      (result, match) => ({ ...result, [match.project.id]: match }),
      {} as Record<string, ProjectAndPeeps>
    );
};

type SortedByBookingDateProjects = {
  project: PeepProject;
  closestBookingDate: Date | null;
  sortedPeeps: SortedByBookingDatePeeps[];
};

export const orderBookingsByProject = (
  bookings: Peep[] | undefined,
  filters: SearchOptions
): (PeepProject | PeepRow)[] | undefined => {
  if (!bookings) {
    return undefined;
  }

  let peepsGroupedByProject: Record<
    string,
    ProjectAndPeeps
  > = groupPeepsByProject(bookings);

  peepsGroupedByProject = secondPassFilter(peepsGroupedByProject, filters);

  const sortedProjects: SortedByBookingDateProjects[] = sortProjectAndPeeps(
    peepsGroupedByProject
  );

  return sortedProjects.reduce((rows, { project, sortedPeeps }) => {
    const peepRows =
      peepsToPeepRows(
        sortedPeeps.map(({ peep }) => peep),
        project.name
      ) ?? [];
    return [...rows, project, ...peepRows];
  }, [] as (PeepProject | PeepRow)[]);
};

export type ProjectAndPeeps = {
  project: PeepProject;
  peeps: Peep[];
};
