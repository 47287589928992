export const cacheKeys = {
  peeps: 'peeps',
  squads: 'squads',
  lastUpdatedDate: 'lastUpdatedDate',
  isDarkMode: 'isDarkMode'
};

export function getOrSetFromCache<T>(key: string, data: T): T | undefined {
  if (data) {
    saveToCache(key, data);
    return data;
  } else {
    return getFromCache(key);
  }
}

export function getFromCache<T>(key: string): T | undefined {
  try {
    const cacheData = localStorage.getItem(key);
    if (!cacheData) {
      return undefined;
    }

    return JSON.parse(cacheData);
  } catch (err) {
    console.error(err);
  }

  return undefined;
}

export function saveToCache<T>(key: string, data: T): void {
  localStorage.setItem(key, JSON.stringify(data));
}