import React, { CSSProperties } from 'react';
import { COL_HEADER_WIDTH, HEADER_HEIGHT, GRID_GAP } from './GridDefaults';
import { BookingRange } from '../../services/models';
import { makeStyles } from '@material-ui/core';
import BookingComponent from './Booking';
import clsx from 'clsx';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  row: {
    height: 50,
  },
  relative: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  cell: {
    border: `dotted ${blueGrey[200]}`,
    borderLeftWidth: '0',
    borderBottomWidth: '1px',
    borderTopWidth: '0',
    borderRightWidth: '1px',
    position: 'relative',
    height: '100%',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns:
      '[monday] 1fr [tuesday] 1fr [wednesday] 1fr [thursday] 1fr [friday] 1fr [end]',
    gridGap: GRID_GAP,
    alignContent: 'start',
    padding: 4,
    gridAutoFlow: 'column dense',
  },
  bordered: {
    border: `dotted ${blueGrey[200]}`,
    borderLeftWidth: '1px',
    borderBottomWidth: '0',
    borderTopWidth: '1px',
    borderRightWidth: '0',
  },
  sticky: {
    position: 'sticky',
    zIndex: 7,
    backgroundColor: '#fff',
  },
  wrapper: {
    width: '100%',
    height: '100%',
  },
}));

export const BookingCell: React.FC<{
  bookingRanges: BookingRange[];
  isScrolling: boolean;
  style: CSSProperties;
}> = ({ bookingRanges, isScrolling, style }) => {
  const classes = useStyles();

  const top = (style?.top as number) + HEADER_HEIGHT;
  const left = (style?.left as number) + COL_HEADER_WIDTH;

  return (
    <div
      className={clsx(classes.cell, classes.grid)}
      style={{ ...style, top, left }}
    >
      {bookingRanges.map(bookingRange => {
        return (
          <BookingComponent
            key={bookingRange.key}
            bookingRange={bookingRange}
            deemphasise={!bookingRange.emphasise}
            noTitle={false}
            showDetail={!isScrolling}
          />
        );
      })}
    </div>
  );
};

export default BookingCell;
