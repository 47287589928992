export enum BookingTypes {
  General = 'General',
  Leave = 'Leave',
  SoftBooking = 'SoftBooking',
  HardBooking = 'HardBooking',
  BackToBase = 'BackToBase',
  TelstraInternalSoft = 'TelstraInternalSoft',
  TelstraInternalHard = 'TelstraInternalHard',
  PlannedPD = 'PlannedPD',
  PublicHoliday = 'PublicHoliday',
}

export const isBooking = (bookingType: string) => {
  return [
    "Hard",
    "Soft",
    BookingTypes.SoftBooking,
    BookingTypes.HardBooking,
    BookingTypes.TelstraInternalSoft,
    BookingTypes.TelstraInternalHard,
  ].includes(bookingType);
};

export type Squad = {
  id: number;
  name: string;
};

export type Project = {
  id: string;
  name: string;
  client: string;
};

export type ConsultantWithBookings = {
  bookings: Record<string, BookingRange[]>;
  projectIds: number[];
};

export type BookingRange = {
  key?: string;
  startDay: number;
  endDay: number;
  project: string;
  projectId: string;
  source?: string;
  salesRepresentative?: string;
  client?: string;
  task?: string;
  bookingType: BookingTypes;
  projectSquad?: number;
  hours: (number | null)[];
  emphasise: boolean;
  getHoursRemaining?: () => number;
};

export type BookingRecord = Record<string, ConsultantWithBookings>;

export type ProjectRecord = Record<string, Project>;

export type PeepRecord = Record<string, Peep>;

export interface Peep {
  id: string;
  givenName: string;
  familyName: string;
  positionTitle: string;
  bookings: Booking[];
  department?: string;
  interSquad?: string;
}

export interface Booking {
  type: string;
  project: PeepProject;
  source?: string;
  startDate: string;
  endDate: string;
  bookingsByDay: BookingByDay[];
}

export interface PeepProject {
  id: string;
  name: string;
  customer?: Customer;
  salesRepresentative?: SalesRep;
}

export interface Customer {
  id: string;
  name: string;
}

export interface SalesRep {
  name: string;
}

export interface BookingByDay {
  id: string;
  date: string;
  hours: number;
}

export interface MappedBookings {
  rows: number;
  columns: number;
  data: MappedBookingsData;
}

export interface MappedBookingsData {
  peeps?: Peep[];
}

export type Params = {
  squads: number | number[];
  startDate?: string;
  endDate?: string;
};

export interface GqlNonWorkingTime {
  type: string;
  status: string;
  source: string;
  startDate: string;
  endDate: string;
  hours: number;
};
