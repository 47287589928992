import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Paper,
} from '@material-ui/core';
import {
  amber,
  green,
  grey,
  purple,
  pink,
  blue,
} from '@material-ui/core/colors';
import clsx from 'clsx';
import { BookingRange, BookingTypes } from '../../services/models';
import { BookingPopover } from './BookingPopover';
import { ROW_HEIGHT, GRID_GAP, COLUMN_WIDTH } from './GridDefaults';
const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    booking: {
      backgroundColor: grey[400],
      color: theme.palette.getContrastText(grey[400]),
      display: 'inline-flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      overflow: 'hidden',
      padding: '3px 5px',
      width: '100%',
      height: ROW_HEIGHT - GRID_GAP,
      cursor: 'pointer',
    },
    // In safari there is a bug where a booking over a single day causes the grid layout to not be respected
    // https://dev.azure.com/readify/Labs/_boards/board/t/Pedro/Backlog%20items/?workitem=26419
    singleDay: {
      maxWidth: COLUMN_WIDTH / 5 - GRID_GAP,
    },
    title: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    shadow: {
      backgroundColor: grey[400],
      opacity: 0.5,
    },
    hours: {
      display: 'flex',
      flexFlow: 'row nowrap',
      '& > div': {
        flex: '1 0 auto',
        textAlign: 'center',
      },
    },
    mondayStart: {
      gridColumnStart: 'monday',
    },
    mondayEnd: {
      gridColumnEnd: 'tuesday',
    },
    tuesdayStart: {
      gridColumnStart: 'tuesday',
    },
    tuesdayEnd: {
      gridColumnEnd: 'wednesday',
    },
    wednesdayStart: {
      gridColumnStart: 'wednesday',
    },
    wednesdayEnd: {
      gridColumnEnd: 'thursday',
    },
    thursdayStart: {
      gridColumnStart: 'thursday',
    },
    thursdayEnd: {
      gridColumnEnd: 'friday',
    },
    fridayStart: {
      gridColumnStart: 'friday',
    },
    fridayEnd: {
      gridColumnEnd: 'end',
    },
    [BookingTypes.Leave]: {
      backgroundColor: amber[400],
      color: theme.palette.getContrastText(amber[400]),
    },
    [BookingTypes.HardBooking]: {
      backgroundColor: green[400],
      color: theme.palette.getContrastText(green[400]),
    },
    [BookingTypes.SoftBooking]: {
      backgroundColor: green[100],
      color: theme.palette.getContrastText(green[100]),
    },
    [BookingTypes.BackToBase]: {
      backgroundColor: purple[400],
      color: theme.palette.getContrastText(purple[400]),
    },
    [BookingTypes.TelstraInternalSoft]: {
      backgroundColor: pink[200],
      color: theme.palette.getContrastText(pink[200]),
    },
    [BookingTypes.TelstraInternalHard]: {
      backgroundColor: pink[400],
      color: theme.palette.getContrastText(pink[400]),
    },
    [BookingTypes.PlannedPD]: {
      backgroundColor: blue[400],
      color: theme.palette.getContrastText(blue[400]),
    },
  })
);

interface BookingProps {
  bookingRange: BookingRange;
  deemphasise?: boolean;
  noTitle?: boolean;
  showDetail?: boolean;
}

const Booking: React.FC<BookingProps> = ({
  bookingRange,
  deemphasise = false,
  noTitle = false,
  showDetail = true,
}) => {
  const classes = useStyles();
  const startClass = `${DAYS[bookingRange.startDay - 1]}Start`;
  const endClass = `${DAYS[bookingRange.endDay - 1]}End`;

  const [showHours, setShowHours] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setShowHours(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowHours(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Paper
        onClick={handleClick}
        elevation={deemphasise ? 0 : 1}
        className={clsx(
          classes.booking,
          classes[startClass as keyof typeof classes],
          classes[endClass as keyof typeof classes],
          bookingRange.hours.length === 1 ? classes.singleDay : '',
          deemphasise ? classes.shadow : classes[(bookingRange.bookingType as unknown) as keyof typeof classes],
        )}
        title={!!bookingRange.client ? `(${bookingRange.client}) ${bookingRange.project}` : bookingRange.project}
      >
        {showDetail && !noTitle && (
          <span className={classes.title}>{bookingRange.project}</span>
        )}
        {showDetail && showHours && (
          <div className={classes.hours}>
            {bookingRange.hours.map((hour, index) => {
              if (!hour) return null;
              return (
                <Typography variant="caption" component="div" key={index}>
                  {hour.toFixed(1)}
                </Typography>
              );
            })}
          </div>
        )}
      </Paper>
      {showDetail && anchorEl && (
        <BookingPopover
          bookingRange={bookingRange}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
};

export default Booking;
