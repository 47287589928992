import React, { memo, ReactNode } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  COLUMN_WIDTH,
  ROW_HEIGHT,
  HEADER_HEIGHT,
  ROW_PADDING,
  COL_HEADER_WIDTH,
} from './Grid/GridDefaults';
import { blueGrey } from '@material-ui/core/colors';

const cols = [1, 2, 3, 4, 5];
const rows = [] as number[];

const rowCount = 30;

for (let i = 0; i < rowCount; i++) {
  rows.push(i);
}

const widths = cols.map(i => `${COLUMN_WIDTH}px`).join(' ');
const heights = rows.map(i => `${ROW_HEIGHT + ROW_PADDING * 2}px`).join(' ');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    table: {
      display: 'grid',
      gridTemplateColumns: `${COL_HEADER_WIDTH}px ${widths}`,
      gridTemplateRows: `${HEADER_HEIGHT}px ${heights}`,
      minHeight: '100%',
    },
    skeletonBooking: {
      backgroundColor: theme.shadows[10],
      maxWidth: '100%',
      padding: '1rem',
      width: '100%',
      height: '100%',
    },
    cell: {
      gridRow: 'auto',
      gridColumn: 'auto',
      border: `dotted ${blueGrey[200]}`,
      borderLeftWidth: '0',
      borderBottomWidth: '1px',
      borderTopWidth: '0',
      borderRightWidth: '1px',
    },
  })
);

const TableSkeleton: React.FC = () => {
  const classes = useStyles();

  const cells = [] as ReactNode[];

  for (let i = 0; i < rowCount * 5; i++) {
    cells.push(
      <div className={classes.cell} key={i}>
        {Math.random() < 0.2 && (
          <Skeleton variant="rect" className={classes.skeletonBooking}>
            <Skeleton variant="text" />
          </Skeleton>
        )}
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.table}>{cells}</div>
    </div>
  );
};

export default memo(TableSkeleton);
