import React from 'react';
import { PeepProject } from '../../services/models';
import { makeStyles, Theme } from '@material-ui/core';
import GridUserHeader from '../Grid/UserHeader';
import {
  ROW_HEIGHT,
  COL_HEADER_WIDTH,
  ROW_PADDING,
} from '../Grid/GridDefaults';
import { PeepRow } from '../../services/mapper';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    alignItems: 'center',
    width: COL_HEADER_WIDTH,
    height: ROW_HEIGHT + 1.5,
    textAlign: 'left',
    padding: theme.spacing(2, 0, 0.5, 1),
    margin: '-1.5px 0 0 0',
    borderTop: `1px solid ${blueGrey[200]}`,
    borderBottom: `1px solid ${blueGrey[200]}`,
    backgroundColor: theme.palette.background.default,
    whiteSpace: 'nowrap',
    minWidth: COL_HEADER_WIDTH,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    position: 'sticky',
    left: 0,
    zIndex: 10,
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

const ColumnHeaders: React.FC<{
  data: (PeepRow | PeepProject)[];
  width: number;
}> = ({ data }) => {
  const classes = useStyles();
  return (
    <>
      {data.map((row, index) => {
        if ('givenName' in row) {
          const overlap =
            row.overlappingBookings > 0 ? row.overlappingBookings : 1;
          return (
            <GridUserHeader
              key={index}
              style={{
                left: 0,
                width: 200,
                height: ROW_HEIGHT * overlap + ROW_PADDING * 2,
              }}
              {...row}
            />
          );
        } else {
          return (
            <div className={classes.cell} key={index}>
              {row.name} {row.customer ? ' - ' + row.customer.name : ''}
            </div>
          );
        }
      })}
    </>
  );
};

export default ColumnHeaders;
