import React from 'react';
import {
  makeStyles,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Link,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ReactComponent as WavingPedro } from '../assets/pedro-waving.svg';

const useStyles = makeStyles(() =>
  createStyles({
    help: {
      cursor: 'pointer',
      fontSize: '1.5rem',
      marginLeft: '10px',
    },
    dialog: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    dialogTitle: {
      paddingTop: '0',
      color: 'white',
    },
    dialogContent: {
      margin: '1rem 0 1rem 0',
    },
    pedro: {
      width: '12rem',
      height: '10rem',
      margin: '1rem 1.5rem 0 0',
      alignSelf: 'center',
      fill: '#fff',
    },
    header: {
      backgroundColor: 'black',
    },
  })
);

const InformationDialog: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <HelpOutlineIcon className={classes.help} onClick={handleClickOpen} />
      <Dialog className={classes.dialog} onClose={handleClose} open={open}>
        <div className={classes.header}>
          <WavingPedro className={classes.pedro} />
          <DialogTitle className={classes.dialogTitle}>
            Want to help me out or have feedback?
          </DialogTitle>
        </div>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body1">
            Here is where everything goes down: <br />
            <Link
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=3Hpq_N5TrUSI_K94O7sdblCCmfPRvLFHsDHRd2NwbilUOENTTEgzUEUyMEg3S0NaSkVHVFNEMTVTQi4u"
              target="_blank"
            >
              Feedback Form
            </Link>
            <br />
            <Link
              href="https://teams.microsoft.com/l/channel/19%3a837c5a602bd542b9b6a98953ad55a788%40thread.tacv2/Team%2520-%2520Pedro?groupId=517c128f-060e-455b-8aa3-c6ee6b322e21&tenantId=fc6a7adc-53de-44ad-88fc-af783bbb1d6e"
              target="_blank"
            >
              Teams Channel
            </Link>
            <br />
            <Link
              href="https://dev.azure.com/readify/Labs/_git/Pedro"
              target="_blank"
            >
              Azure DevOps Repo
            </Link>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InformationDialog;
