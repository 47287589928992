import React, { Dispatch } from 'react';
import { ReactComponent as OhNoPedro } from '../assets/pedro-nametag-shocked.svg';
import { makeStyles, createStyles, Typography, Theme } from '@material-ui/core';
import ClearAllButton from './ClearAllButton';
import { AppAction } from '../services/appReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divStyle: {
      position: 'relative',
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    pedro: {
      width: '16rem',
      height: '11rem',
      marginRight: '1.5rem',
      fill: '#fff',
    },
    clearAll: {
      width: '16rem',
      margin: 'Auto',
      justifyContent: 'center',
    },
  })
);

const NoResults: React.FC<{ dispatch: Dispatch<AppAction> }> = ({
  dispatch,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.divStyle}>
      <OhNoPedro className={classes.pedro} />
      <Typography>
        Oh no, I couldn't find anything matching <br />
        your search criteria!
      </Typography>
      <div className={classes.clearAll}>
        <ClearAllButton text="Clear Filters" dispatch={dispatch} />
      </div>
    </div>
  );
};

export default NoResults;
