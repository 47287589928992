import React, { Dispatch, useState } from 'react';
import FilterControls from './FilterControls';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles, createStyles } from '@material-ui/core';
import { AppAction, BookingType, ViewMode } from '../services/appReducer';
import { Squad } from '../services/models';
import { Peep } from '../services/models';

/**
 * Q. Why is this statically positioned?
 * A. Our table headers are virtual, using a specialised table component.
 *    If we want content to be at the far right of the table view, we can't actually put it in the header,
 *    it needs to be statically positioned to appear as though it's in the header.
 */

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      marginLeft: '10px',
      fontSize: '2rem',
      cursor: 'pointer',
    },
  })
);

export interface FilterPanelManagerProps {
  state: {
    viewMode: ViewMode;
    squadId: number;
    bookingTypes: BookingType[];
    searchByRole: string[];
    searchByProject: string[];
    searchByClient: string[];
    searchByConsultant: string[];
    searchByCapability: string[];
    squads: Squad[];
    unfilteredPeeps: Peep[];
  };
  dispatch: Dispatch<AppAction>;
}

const FilterPanelManager: React.FC<FilterPanelManagerProps> = props => {
  const [controlsVisible, setControlsVisible] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <div>
        <TuneIcon
          fontSize="large"
          className={classes.icon}
          onClick={() => setControlsVisible(!controlsVisible)}
        />
      </div>
      {controlsVisible && <FilterControls {...props} />}
    </>
  );
};

export default FilterPanelManager;
