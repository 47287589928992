import React, { CSSProperties } from 'react';
import { PeepRow } from '../../services/mapper';
import GridCell from '../Grid/BookingCell';

interface BookingViewCellProps {
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  data: PeepRow[];
  isScrolling: boolean;
}

const BookingViewCell: React.FC<BookingViewCellProps> = ({
  columnIndex,
  rowIndex,
  data,
  ...rest
}) => {
  if (!data?.[rowIndex]?.weeks?.[columnIndex]) {
    return null;
  }

  const { bookingRanges } = data[rowIndex].weeks[columnIndex];

  return <GridCell bookingRanges={bookingRanges} {...rest} />;
};

export default BookingViewCell;
